import store from '@/store'
import {
  mdiAccountCheckOutline,
  mdiAccountOutline,
  mdiAccountPlusOutline,
  mdiAccountRemoveOutline,
  mdiAccountGroupOutline,
  mdiAccountCogOutline,
  mdiDnsOutline,
  mdiAccountCashOutline,
  mdiAccountEditOutline,
} from '@mdi/js'
import { ref } from '@vue/composition-api'

export default function useEvaluationList() {

  const evaluationListTable = ref([])

  const tableColumns = [
    { text: 'PROYECTO', value: 'description', width: 350, cellClass: 'py-2' },
    { text: 'RESPONSABLE', value: 'project_manager' },
    { text: 'INICIO', value: 'date_entry', width: 130 },
    { text: 'EQUIPO COMITÉ', value: 'committee_users', sortable: false },
    { text: 'ESTADO', value: 'status_text' },
    {
      text: 'ACCIONES',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]
  const filterLists = ref([])
  filterLists.value =  [{
    value: 'status_text',
    list: [{text: 'Finalizado', value: 'Finalizado'}, {text: 'Sin Respuestas', value: 'Sin Respuestas'}, {text: 'Parcialmente', value: 'Parcialmente'}]
  }]


  const filterFields = [
    { text: 'PROYECTO', value: 'description', type: 'text' },
    { text: 'RESPONSABLE', value: 'project_manager', type: 'text'},
    { text: 'INICIO', value: 'date_entry', type: 'date'},
    { text: 'ESTADO', value: 'status_text', type: 'lookup' },
  ]

  const roleOptions = [
    { text: 'Administrador', value: 'admin' },
    { text: 'Gestor', value: 'project_manager' },
    { text: 'Comité Evaluador', value: 'commitee' },
    { text: 'Auditor', value: 'auditor' },
    { text: 'Patrocinador', value: 'sponsor' },
    { text: 'Miembro', value: 'team_member' },
  ]

  const loading = ref(false)
  const options = ref({
    sortBy: ['id'],
    sortDesc: [false],
  })

  // fetch data
  const fetchEvaluationList = async () => {
    try {
      loading.value = true
      let response = await store.dispatch('evaluation/fetchEvaluation')
      const data = ref([])
      if (response) {
        data.value = JSON.parse(response[0].committeeproject)['committee_projects']
        evaluationListTable.value = data.value ? data.value : []
        loading.value = false
        return response
      } else {
        evaluationListTable.value = []
        loading.value = false
        return response
      }
    } catch (error) {
      loading.value = false
    }
  }


  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'team_member') return 'secondary'
    if (role === 'commitee') return 'warning'
    if (role === 'project_manager') return 'info'
    if (role === 'sponsor') return 'success'
    if (role === 'admin') return 'error'
    if (role === 'auditor') return 'accent'

    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'team_member') return mdiAccountOutline
    if (role === 'commitee') return  mdiAccountGroupOutline
    if (role === 'project_manager') return mdiAccountCogOutline
    if (role === 'sponsor') return mdiAccountCashOutline
    if (role === 'admin') return mdiDnsOutline
    if (role === 'auditor') return mdiAccountEditOutline

    return mdiAccountOutline
  }

  const resolveUserStatusVariant = status => {
    if (status) return 'success'
    else return 'error'
  }

  const resolveUserTotalIcon = total => {
    if (total === 'Total Users') return { icon: mdiAccountOutline, color: 'primary' }
    if (total === 'Paid Users') return { icon: mdiAccountPlusOutline, color: 'error' }
    if (total === 'Active Users') return { icon: mdiAccountCheckOutline, color: 'success' }
    if (total === 'Pending Users') return { icon: mdiAccountRemoveOutline, color: 'warning' }

    return { icon: mdiAccountOutline, color: 'primary' }
  }

  return {
    fetchEvaluationList,
    filterFields,
    filterLists,
    evaluationListTable,
    tableColumns,
    loading,
    options,
    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    resolveUserTotalIcon,
    roleOptions
  }
}
