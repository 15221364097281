var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[(_vm.showSurvey)?_c('dialog-show-survey',{attrs:{"id":_vm.projectId,"projectSurveyId":_vm.projectSurveyId,"answer":_vm.answer},on:{"updated":_vm.updated},model:{value:(_vm.showSurvey),callback:function ($$v) {_vm.showSurvey=$$v},expression:"showSurvey"}}):_vm._e(),_c('v-card-title',{staticClass:"pb-2"},[_vm._v(" Evaluación "),_c('v-spacer'),_c('div',{staticClass:"d-flex align-center flex-wrap"},[_c('v-btn',{staticClass:"mb-1",attrs:{"color":"secondary","outlined":""}},[_c('v-icon',{staticClass:"me-1",attrs:{"size":"17"}},[_vm._v(" "+_vm._s(_vm.icons.mdiExportVariant)+" ")]),_c('span',[_vm._v("Exportar")])],1)],1)],1),_c('v-divider',{staticClass:"mt-2"}),_c('v-card-text',{staticClass:"d-flex align-center flex-wrap pb-0 pl-0 pt-0"},[_c('v-col',{attrs:{"cols":"12"}},[_c('filter-by',{attrs:{"filterFields":_vm.filterFields,"filterLists":_vm.filterLists},model:{value:(_vm.newItems),callback:function ($$v) {_vm.newItems=$$v},expression:"newItems"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.tableColumns,"items":_vm.newItems,"options":_vm.options,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.project_manager",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{class:item.avatar ? '' : 'v-avatar-light-bg primary--text',attrs:{"color":item.avatar ? '' : 'primary',"size":"30"}},[(item.avatar)?_c('v-img',{attrs:{"src":require(("@/assets/images/avatars/" + (item.avatar)))}}):_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.avatarText(item.project_manager)))])],1),_c('div',{staticClass:"d-flex flex-column ms-3 text--primary font-weight-semibold text-truncate "},[_vm._v(" "+_vm._s(item.project_manager)+" ")])],1)]}},{key:"item.date_entry",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.dating(item.date_entry))+" ")])]}},{key:"item.committee_users",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"v-avatar-group2",class:_vm.rootThemeClasses},_vm._l((item.committee_users),function(user){return _c('v-tooltip',{key:user.id,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({class:user.avatar ? '' : ("v-avatar-light-bg " + (_vm.resolveUserColorVariant(user, item.structure_survey)) + "--text pointer"),attrs:{"color":user.avatar ? '' : ("" + (_vm.resolveUserColorVariant(user, item.structure_survey))),"size":"35"},on:{"click":function($event){return _vm.showUser($event, user, item.structure_survey)}}},'v-avatar',attrs,false),on),[(user.avatar)?_c('v-img',{attrs:{"src":require(("@/assets/images/avatars/" + (user.avatar)))}}):_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.avatarText(user.fullname)))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(user.fullname))])])}),1)]}},{key:"item.status_text",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-chip',{staticClass:"v-chip-light-bg font-weight-semibold text-capitalize font14",class:((_vm.resolveStatusVariant(item.status_value)) + "--text"),attrs:{"small":"","color":_vm.resolveStatusVariant(item.status_value)}},[_vm._v(" "+_vm._s(item.status_text)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.show(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"18","color":"info"}},[_vm._v(" "+_vm._s(_vm.icons.mdiSendCircleOutline)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Responder Encuesta")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.setPdf(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"18","color":"error"}},[_vm._v(" "+_vm._s(_vm.icons.mdiFilePdfBox)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Exportar .pdf")])])],1)]}}],null,true)})],1),_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"500","position-x":_vm.user.x,"position-y":_vm.user.y,"offset-y":""},model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',{staticClass:"py-1 elevation-6",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"pa-3"},[_vm._v(" Estado Evaluación ")]),_c('div',{staticClass:"d-flex align-center pa-2"},[_c('v-progress-circular',{attrs:{"rotate":90,"size":60,"value":(_vm.numberAnswers / _vm.numberQuestions) * 100,"color":_vm.resolveUserStatusVariant(_vm.user.status)}},[_vm._v(" "+_vm._s((_vm.numberAnswers / _vm.numberQuestions) * 100)+"% ")]),_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('div',{staticClass:"subtitle-1 font-weight-semibold text--primary"},[_vm._v(" "+_vm._s((_vm.numberAnswers + " Respuestas / " + _vm.numberQuestions + " Preguntas"))+" ")]),_c('div',[_c('v-chip',{staticClass:"v-chip-light-bg font-weight-semibold text-capitalize subtitle-1",class:((_vm.resolveUserStatusVariant(_vm.user.status)) + "--text"),attrs:{"small":"","color":_vm.resolveUserStatusVariant(_vm.user.status)}},[_vm._v(" "+_vm._s(_vm.user.status)+" ")])],1)])],1)],1)],1),(_vm.downloadPdf)?_c('report',{attrs:{"project":_vm.project,"charts":_vm.charts,"download":_vm.clearPdf()}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }