<template>
  <div>
    <!-- list filters -->
    <v-card>
      <dialog-show-survey
        v-model="showSurvey"
        v-if="showSurvey"
        :id="projectId"
        :projectSurveyId="projectSurveyId"
        :answer="answer"
        @updated="updated"
      ></dialog-show-survey>
      <v-card-title class="pb-2">
        Evaluación
        <v-spacer></v-spacer>
        <div class="d-flex align-center flex-wrap">
          <v-btn
            color="secondary"
            outlined
            class="mb-1"
          >
            <v-icon
              size="17"
              class="me-1"
            >
              {{ icons.mdiExportVariant }}
            </v-icon>
            <span>Exportar</span>
          </v-btn>
        </div>
      </v-card-title>

      <v-divider class="mt-2"></v-divider>
      <v-card-text class="d-flex align-center flex-wrap pb-0 pl-0 pt-0">
        <v-col cols="12">
          <filter-by v-model="newItems" :filterFields="filterFields" :filterLists="filterLists"></filter-by>
        </v-col>
      </v-card-text>

      <v-data-table
        :headers="tableColumns"
        :items="newItems"
        :options.sync="options"
        :loading="loading"
      >
        <template #[`item.project_manager`]="{item}">
          <div class="d-flex align-center">
            <v-avatar
              :color="item.avatar ? '' : 'primary'"
              :class="item.avatar ? '' : 'v-avatar-light-bg primary--text'"
              size="30"
            >
              <v-img
                v-if="item.avatar"
                :src="require(`@/assets/images/avatars/${item.avatar}`)"
              ></v-img>
              <span
                v-else
                class="font-weight-medium"
              >{{ avatarText(item.project_manager) }}</span>
            </v-avatar>

            <div class="d-flex flex-column ms-3 text--primary font-weight-semibold text-truncate ">
                {{ item.project_manager }}
            </div>
          </div>
        </template>

        <template #[`item.date_entry`]="{item}">
          <td >
            {{ dating(item.date_entry) }}
          </td>
        </template>

        <template #[`item.committee_users`]="{item}">
          <div
            class="v-avatar-group2"
            :class="rootThemeClasses"
          >
            <v-tooltip
              v-for="user of item.committee_users"
              :key="user.id"
              bottom
            >
              <template #activator="{ on, attrs }">
                <v-avatar
                  v-bind="attrs"
                  v-on="on"
                  @click="showUser($event, user, item.structure_survey)"
                  :color="user.avatar ? '' : `${resolveUserColorVariant(user, item.structure_survey)}`"
                  :class="user.avatar ? '' : `v-avatar-light-bg ${resolveUserColorVariant(user, item.structure_survey)}--text pointer`"
                  size="35"
                >
                  <v-img
                    v-if="user.avatar"
                    :src="require(`@/assets/images/avatars/${user.avatar}`)"
                  ></v-img>
                  <span
                    v-else
                    class="font-weight-medium"
                  >{{ avatarText(user.fullname) }}</span>
                </v-avatar>
              </template>
              <span>{{ user.fullname }}</span>
            </v-tooltip>
          </div>
        </template>

        <template #[`item.status_text`]="{item, index}">
          <v-chip
            small
            :color="resolveStatusVariant(item.status_value)"
            :class="`${resolveStatusVariant(item.status_value)}--text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize font14"
          >
            {{ item.status_text }}
          </v-chip>
        </template>

        <template #[`item.actions`]="{item}">
          <div class="d-flex align-center justify-center">
          <!-- view -->
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  @click="show(item)"
                >
                  <v-icon size="18" color="info">
                    {{ icons.mdiSendCircleOutline }}
                  </v-icon>
                </v-btn>
              </template>
              <span>Responder Encuesta</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  @click="setPdf(item)"
                >
                  <v-icon size="18" color="error">
                    {{ icons.mdiFilePdfBox }}
                  </v-icon>
                </v-btn>
              </template>
              <span>Exportar .pdf</span>
            </v-tooltip>
          </div>
        </template>

      </v-data-table>
    </v-card>
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      max-width="500"
      :position-x="user.x"
      :position-y="user.y"
      offset-y
    >
      <v-card class="py-1 elevation-6" outlined>
        <v-card-title class="pa-3">
          Estado Evaluación
        </v-card-title>
        <div class="d-flex align-center pa-2">
          <v-progress-circular
            :rotate="90"
            :size="60"
            :value="(numberAnswers / numberQuestions) * 100"
            :color="resolveUserStatusVariant(user.status)"
          >
            {{ (numberAnswers / numberQuestions) * 100 }}%
          </v-progress-circular>
          <div class="d-flex flex-column ms-3">
            <div class="subtitle-1 font-weight-semibold text--primary">
              {{ `${numberAnswers} Respuestas / ${numberQuestions} Preguntas`  }}
            </div>
            <div>
              <v-chip
                small
                :color="resolveUserStatusVariant(user.status)"
                :class="`${resolveUserStatusVariant(user.status)}--text`"
                class="v-chip-light-bg font-weight-semibold text-capitalize subtitle-1"
              >
                {{ user.status }}
              </v-chip>
            </div>
          </div>
        </div>
      </v-card>
    </v-menu>
    <report
      v-if="downloadPdf"
      :project="project"
      :charts="charts"
      :download="clearPdf()"
    ></report>
  </div>

</template>

<script>
import { mdiExportVariant, mdiSendCircleOutline, mdiFilePdfBox } from '@mdi/js'
import FilterBy from '@/components/widgets/filter/FilterBy.vue'
import { ref, watch, provide, computed } from '@vue/composition-api'
import apiAxios from '@/plugins/axios'
import { avatarText, dating } from '@core/utils/filter'
import useVuetify from '@core/utils/vuetify'
import DialogShowSurvey from '@/views/survey/survey-show/DialogShow.vue'
import Report from './Report'
import useEvaluationList from './useEvaluationList'
import { setLoading } from '@core/utils'

export default {
  components: { DialogShowSurvey, FilterBy, Report },
  setup() {
    const downloadPdf = ref(false)
    const menu = ref(false)
    const user = ref({})
    const projectId = ref(0)
    const projectSurveyId = ref(0)
    const survey = ref({})
    const project = ref({})
    const answer = ref({})
    const charts = ref([])
    const status = ref([])
    const showSurvey = ref(false)
    provide('showSurvey', showSurvey)
    provide('survey', survey)
    provide('fullscreen', true)
    const numberQuestions = ref(null)
    const numberAnswers = ref(null)
    const newItems = ref([])
    const userData = JSON.parse(localStorage.getItem('userData'))
    const { rootThemeClasses } = useVuetify()

    const { fetchEvaluationList, evaluationListTable, tableColumns, loading, options, filterFields, filterLists } =
      useEvaluationList()

    const updated = () => {
      fetchEvaluationList()
    }

    fetchEvaluationList()

    watch(
      evaluationListTable,
      () => {
        let values = []
        let numbers = []
        evaluationListTable.value.forEach((item, i) => {
          numbers[i] = item.committee_users ? item.committee_users.length : 0
          values[i] = 0
          let users = item.committee_users ? item.committee_users : []
          users.forEach((user, index) => {
            values[i] += numberAns(user.structure_answer) / JSON.parse(item.structure_survey).elements.length
          })
          status.value[i] = { id: item.id, value: values[i] / numbers[i] }
        })
        evaluationListTable.value.forEach((item, i) => {
          let find = status.value.find(val => val.id == item.id)
          item['status_text'] = find.value == 1 ? 'Finalizado' : find.value == 0 ? 'Sin Respuestas' : 'Parcialmente'
          item['status_value'] = find.value
        })
        newItems.value = evaluationListTable.value
      },
      {
        deep: true,
      },
      {
        immediate: true,
      },
    )

    const structure = computed(() => {
      return project.value.answers.map(ans => {
        let find = project.value.survey.find(item => item.name === ans.name)
        let ele = {
          ...ans,
          title: find.title,
          type: getType(ans.name),
        }
        return ele
      })
    })

    watch(
      project,
      () => {
        structure.value.forEach((item, i) => {
          if (item.type == 'bar') {
            let ele = {
              name: `chart${i + 1}`,
              series: [
                {
                  data: item.answer.answer.map(item => {
                    let val = Object.values(item)
                    return val[0]
                  }),
                },
              ],
              chart: {
                height: 250,
                width: 500,
                type: 'bar',
              },
              plotOptions: {
                bar: {
                  horizontal: false,
                  columnWidth: '35%',
                  borderRadius: 10,
                  dataLabels: {
                    position: 'top', // top, center, bottom
                  },
                },
              },
              dataLabels: {
                enabled: true,
                formatter: function (val) {
                  return val
                },
                offsetY: -20,
                style: {
                  fontSize: '10px',
                  colors: ['#000'],
                },
              },
              xaxis: {
                categories: item.answer.answer.map(item => {
                  let val = Object.keys(item)
                  return val[0]
                }),
                position: 'bottom',
                axisBorder: {
                  show: true,
                },
                axisTicks: {
                  show: true,
                },
                crosshairs: {
                  fill: {
                    type: 'gradient',
                    gradient: {
                      colorFrom: '#D8E3F0',
                      colorTo: '#BED1E6',
                      stops: [0, 100],
                      opacityFrom: 0.4,
                      opacityTo: 0.5,
                    },
                  },
                },
              },
              yaxis: {
                title: {
                  text: 'Respuestas',
                },
                axisBorder: {
                  show: true,
                },
                axisTicks: {
                  show: true,
                },
                labels: {
                  show: true,
                  formatter: function (val) {
                    return val
                  },
                  style: {
                    fontSize: '10px',
                    colors: ['#000'],
                  },
                },
              },
              description: item.title,
            }
            charts.value.push(ele)
          } else {
            let ele = {
              name: `chart${i + 1}`,
              series: item.answer.answer.map(item => {
                let val = Object.values(item)
                return val[0]
              }),
              chart: {
                width: 380,
                type: 'pie',
              },
              dataLabels: {
                enabled: true,
                offsetX: -20,
                style: {
                  colors: ['#000'],
                },
                dropShadow: {
                  enabled: false,
                },
              },
              legend: {
                formatter: function (val, opts) {
                  return val + ' - ' + opts.w.globals.series[opts.seriesIndex]
                },
                position: 'bottom',
                fontSize: '10px',
              },
              description: item.title,
              labels: item.answer.answer.map(item => {
                let val = Object.keys(item)
                return val[0]
              }),
            }
            charts.value.push(ele)
          }
        })
        downloadPdf.value = true
      },
      {
        deep: true,
      },
    )

    const clearPdf = () => {
      downloadPdf.value = false
      charts.value = []
    }

    const getType = str => {
      let surveyType = str.substring(0, str.length - 1)
      if (surveyType === 'yes-no') {
        return 'pie'
      } else {
        return 'bar'
      }
    }

    const show = item => {
      survey.value = JSON.parse(item.structure_survey)
      let finded = item.committee_users.find(ele => ele.user_id === userData.id)
      answer.value = finded.structure_answer ? JSON.parse(finded.structure_answer) : {}
      projectSurveyId.value = finded ? finded.id : 0
      projectId.value = item.id
      showSurvey.value = true
    }

    const numberAns = value => {
      if (value) return Object.keys(JSON.parse(value)).length
      else return 0
    }

    const showUser = (e, userData, survey) => {
      user.value = userData
      user.value.x = e.x
      user.value.y = e.y
      numberAnswers.value = numberAns(userData.structure_answer)
      numberQuestions.value = JSON.parse(survey).elements.length
      let value = numberAnswers.value / numberQuestions.value
      if (value === 0) {
        user.value.status = 'Sin Respuestas'
      } else if (value === 1) {
        user.value.status = 'Finalizado'
      } else {
        user.value.status = 'Parcialmente'
      }
      menu.value = true
    }

    const resolveUserColorVariant = (userData, survey) => {
      let numberAnswers = numberAns(userData.structure_answer)
      let numberQuestions = JSON.parse(survey).elements.length
      let value = numberAnswers / numberQuestions
      if (value === 0) {
        return 'primary'
      } else if (value === 1) {
        return 'success'
      } else {
        return 'warning'
      }
    }

    const resolveUserStatusVariant = status => {
      if (status === 'Finalizado') return 'success'
      else if (status === 'Sin Respuestas') return 'error'
      else return 'warning'
    }

    const resolveStatusVariant = status => {
      if (status === 1) return 'success'
      else if (status === 0) return 'error'
      else return 'warning'
    }

    const setPdf = async item => {
      try {
        setLoading(true)
        const { data } = await apiAxios.get('/projects/' + item.id + '/survey_answers')
        project.value = {
          answers: data.survey.answer.answers.map(ans => {
            let ele = Object.entries(ans)
            let obj = {
              name: ele[0][0],
              answer: ele[0][1],
            }
            return obj
          }),
          date_entry: data.object.date_entry,
          description: data.object.description,
          stakeholders: data.object.stakeholders_committee,
          total_encuestados: data.survey.answer.total_encuestado,
          total_respuestas: data.survey.answer.total_respuestas,
          objective: data.object.objective,
          survey: JSON.parse(data.survey.structure_survey).elements.map(sur => {
            let ele = {
              name: sur.name,
              title: sur.title,
            }
            return ele
          }),
        }
      } catch (error) {
        setLoading(false)
        downloadPdf.value = false
      }
    }

    return {
      answer,
      avatarText,
      charts,
      dating,
      clearPdf,
      downloadPdf,
      filterFields,
      filterLists,
      loading,
      menu,
      newItems,
      numberAnswers,
      numberQuestions,
      options,
      project,
      projectId,
      projectSurveyId,
      resolveStatusVariant,
      resolveUserColorVariant,
      resolveUserStatusVariant,
      rootThemeClasses,
      setPdf,
      show,
      showSurvey,
      showUser,
      status,
      structure,
      tableColumns,
      updated,
      user,
      icons: {
        mdiExportVariant,
        mdiSendCircleOutline,
        mdiFilePdfBox,
      },
    }
  },
}
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>
