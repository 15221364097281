<template>
  <div ref="content">
    <div
      v-for="(chart, i) in charts"
      :key="chart.name + i + 1"
      :ref="chart.name"
    ></div>
  </div>
</template>

<script>
import ApexChart from '@/components/charts/apex-charts/ApexChart.vue'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
pdfMake.vfs = pdfFonts.pdfMake.vfs
import moment from 'moment'
import htmlToPdfmake from 'html-to-pdfmake'
import { alertPopUp, setLoading } from '@/@core/utils/index'

export default {
  components: { ApexChart },
  props: {
    project: {
      default: () => {},
      type: Object,
      required: true,
    },
    charts: {
      default: () => [],
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      apexCharts: [],
      logo: 'img/name-app.png',
      chart: true,
      html: '',
      urls: [],
    }
  },
  methods: {
    async getBase64ImageFromURL(url) {
      return new Promise((resolve, reject) => {
        let img = new Image()
        img.setAttribute('crossOrigin', 'anonymous')
        img.src = url
        img.onload = () => {
          let canvas = document.createElement('canvas')
          canvas.width = img.width
          canvas.height = img.height

          let ctx = canvas.getContext('2d')
          ctx.drawImage(img, 0, 0)

          let dataURL = canvas.toDataURL('image/png')
          resolve(dataURL)
        }

        img.onerror = error => {
          console.log(error)
          reject(error)
        }
      })
    },
    async createPdf() {
      console.log(this.project)
      try {
        let docDefinition = {
          info: {
            title: `Proyecto-${this.project.project_code}`,
          },
          pageMargins: [20, 100, 20, 40],
          defaultStyle: {
            font: 'times',
          },
          styles: {
            'ql-align-justify': {
              'text-align': 'justify',
            },
          },
          header: [
            {
              columns: [
                {
                  image: await this.getBase64ImageFromURL(this.logo),
                  width: 235,
                  height: 30,
                  margin: [30, 30, 0, 0],
                },
                {
                  text: `INFORME ENCUESTA COMITÉ`,
                  fontSize: 19,
                  alignment: 'center',
                  margin: [0, 35, 0, 0],
                  color: '#96242b',
                },
              ],
            },
            {
              canvas: [
                {
                  type: 'line',
                  x1: 40,
                  y1: 13,
                  x2: 595.28 - 40,
                  y2: 13,
                  lineWidth: 10,
                  lineColor: '#d3242b',
                  margin: [0, 0, 0, 10],
                },
              ],
            },
          ],

          footer: function (currentPage, pageCount) {
            return [
              {
                text: `Página ${currentPage.toString()} de ${pageCount}`,
                fontSize: 8,
                alignment: 'left',
                bold: true,
                margin: [20, 20, 0, -10],
              },
              {
                text: `${moment(new Date()).format('DD-MMM-YYYY')}`,
                fontSize: 8,
                alignment: 'right',
                bold: true,
                margin: [0, 20, 20, -10],
              },
            ]
          },
          content: [
            {
              layout: 'firstTable',
              table: {
                widths: ['*', 'auto', '*', 80],
                margin: [20, 10, 0, 20],
                body: [
                  [
                    { text: 'Nombre de reunión', fillColor: '#cfd8dc', bold: true, margin: 7, alignment: 'left' },
                    { text: 'Comité Directivo de Proyectos', margin: 7, alignment: 'left' },
                    { text: 'Fecha de reunión', fillColor: '#cfd8dc', bold: true, margin: 7, alignment: 'left' },
                    { text: `${moment(this.project.date_entry).format('DD-MMM-YYYY')}`, margin: 7, alignment: 'left' },
                  ],
                  [
                    {
                      text: 'Lugar de reunión',
                      rowSpan: 2,
                      fillColor: '#cfd8dc',
                      bold: true,
                      margin: 7,
                      alignment: 'left',
                    },
                    { text: 'Microsoft Teams (Virtual)', rowSpan: 2, margin: 7, alignment: 'left' },
                    {
                      text: 'Hora de inicio',
                      fillColor: '#cfd8dc',
                      bold: true,
                      margin: 7,
                      alignment: 'left',
                    },
                    { text: '', margin: 7, alignment: 'left' },
                  ],
                  [
                    '',
                    '',
                    {
                      text: 'Hora final',
                      fillColor: '#cfd8dc',
                      bold: true,
                      margin: 7,
                      alignment: 'left',
                    },
                    { text: '', margin: 7, alignment: 'left' },
                  ],
                ],
              },
            },
            {
              text: `${this.project.description}`,
              fontSize: 14,
              alignment: 'center',
              bold: true,
              margin: [0, 30, 0, 5],
            },
            {
              text: `OBJETIVOS:`,
              fontSize: 14,
              alignment: 'left',
              italics: true,
              margin: [0, 20, 0, 5],
            },
            {
              canvas: [
                {
                  type: 'line',
                  x1: 0,
                  y1: 2,
                  x2: 595.28 - 40,
                  y2: 2,
                  lineWidth: 1,
                  lineColor: '#d3242b',
                },
              ],
            },
            this.html,
            {
              canvas: [
                {
                  type: 'line',
                  x1: 0,
                  y1: 2,
                  x2: 595.28 - 40,
                  y2: 2,
                  lineWidth: 1,
                  lineColor: '#d3242b',
                },
              ],
            },
            ...this.urls.map(res => {
              let ele = []
              let title = {
                text: `${res.title}`,
                fontSize: 12,
                bold: true,
                alignment: 'center',
                margin: [0, 50, 0, 5],
              }
              ele.push(title)
              let img = {
                image: res.url,
                alignment: 'center',
                margin: [30, 0, 30, 30],
              }
              ele.push(img)
              return ele
            }),
            {
              canvas: [
                {
                  type: 'line',
                  x1: 0,
                  y1: 2,
                  x2: 595.28 - 40,
                  y2: 2,
                  lineWidth: 1,
                  lineColor: '#d3242b',
                },
              ],
            },
            {
              text: [
                {
                  text: `Total respuestas:   `,
                  fontSize: 14,
                  alignment: 'left',
                  bold: true,
                },
                {
                  text: `${this.project.total_respuestas} `,
                  fontSize: 14,
                  alignment: 'left',
                },
              ],
              margin: [0, 20, 0, 5],
            },
            {
              text: `Se contó con la participación de los siguientes integrantes:`,
              fontSize: 11,
              alignment: 'left',
              margin: [0, 20, 0, 5],
            },
            this.project.stakeholders.map((res, i) => {
              if (res) {
                let ele = {
                  text: `${i + 1} - ${res.fullname}`,
                  fontSize: 11,
                  alignment: 'left',
                  margin: [20, 5, 0, 0],
                }
                return ele
              }
            }),
          ],
        }

        const win = window.open('', '_blank')
        if (win) pdfMake.createPdf(docDefinition).open({}, win)
        else alertPopUp(win)
        setLoading(false)

        this.$nextTick(() => {
          this.$emit('download')
        })
      } catch (error) {
        console.log(error)
      }
    },
  },
  async mounted() {
    pdfMake.fonts = {
      times: {
        normal: 'times.ttf',
        bold: 'times-bold.ttf',
        italics: 'times-it.ttf',
        bolditalics: 'times.ttf',
      },
      Roboto: {
        normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf',
      },
    }
    pdfMake.tableLayouts = {
      firstTable: {
        hLineColor: function (i) {
          return '#aaa'
        },
        vLineColor: function (i) {
          return '#aaa'
        },
      },
      secondTable: {
        hLineColor: function (i) {
          return '#aaa'
        },
        vLineColor: function (i) {
          return '#aaa'
        },
      },
    }
    this.html = this.project.objective ? htmlToPdfmake(this.project.objective) : ''
    let um = this
    /* await this.charts.reduce( async (prevProm, chart) => {
      await prevProm
      let elchart = new ApexCharts(this.$refs[`${chart.name}`], chart)
      console.log(elchart)
      elchart.render().then(async () => {
        await setTimeout(() => {
          elchart.dataURI().then(uri => {
            um.urls.push(uri.imgURI)
            return Promise.resolve()
          })
        }, 1000)
      })
    }, Promise.resolve()) */

    this.charts.forEach((chart, i) => {
      let elchart = new ApexCharts(this.$refs[`${chart.name}`][0], chart)
      elchart.render().then(val => {
        setTimeout(() => {
          elchart.dataURI().then(uri => {
            um.urls.push({ url: uri.imgURI, title: chart.description })
            if (um.urls.length === um.charts.length) {
              um.createPdf()
            }
          })
        }, 1000)
      })
    })

    /* for (let chart of this.charts) {
      let elchart = new ApexCharts(this.$refs[`${chart.name}`], chart)
      console.log(this.$refs[`${chart.name}`])
      elchart.render().then(val => {
        setTimeout(() => {
          elchart.dataURI().then(uri => {
            um.urls.push(uri.imgURI)
          })
        }, 1000)
      })
    } */

    /* const elchart2 = new ApexCharts(this.$refs.chart, this.charts[0])
    elchart2.render().then(() => {
      setTimeout(() => {
        elchart2.dataURI().then(uri => {
          um.uri2 = uri.imgURI
          if (um.uri2) {
            um.createPdf()
          }
        })
      }, 1000)
    }) */
  },
}
</script>

<style scoped>
</style>
